import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript, TrafficLayer } from '@react-google-maps/api';
import axios from 'axios';
import mapStyles from '../../assets/estilos/mapStyles';

const MostrarMapa = () => {
  const markerRefs = useRef({}); // Referencia para manejar los marcadores directamente
  const mapRef = useRef(null); // Referencia al mapa
  const [mapLoaded, setMapLoaded] = useState(false); // Estado para controlar si el mapa está listo

  const fetchData = async () => {
    try {
      const imeis = ['864035053176690'];
      const response = await axios.post('https://demo.edgarleal.es/ConsultarTracker.php', {
        imeis: imeis,
      });

      console.log('Datos recibidos:', response.data);

      if (!mapRef.current) {
        console.error('El mapa aún no está listo.');
        return;
      }

      const { google } = window; // Asegura que el objeto `google` esté disponible
      if (!google || !google.maps) {
        console.error('El objeto google no está disponible.');
        return;
      }

      response.data.forEach((item) => {
        if (!item.latitude || !item.longitude) {
          console.error(`Datos inválidos para IMEI ${item.imei}:`, item);
          return; // Ignorar este marcador si los datos no son válidos
        }

        const markerId = item.imei;

        if (markerRefs.current[markerId]) {
          // Actualizar la posición del marcador existente
          markerRefs.current[markerId].setPosition({
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          });
          console.log(`Marcador actualizado para IMEI ${markerId}`);
        } else {
          // Crear un nuevo marcador
          markerRefs.current[markerId] = new google.maps.Marker({
            position: {
              lat: parseFloat(item.latitude),
              lng: parseFloat(item.longitude),
            },
            map: mapRef.current,
            title: `IMEI: ${item.imei}`,
          });
          console.log(`Marcador creado para IMEI ${markerId}`);
        }
      });
    } catch (error) {
      console.error('Error al obtener los datos de la API:', error);
    }
  };

  useEffect(() => {
    let interval;
    if (mapLoaded) {
      fetchData(); // Llama a la función una vez cuando el mapa está cargado
      interval = setInterval(fetchData, 2000); // Configura el intervalo de actualización
    }
    return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
  }, [mapLoaded]); // Solo ejecuta cuando el mapa está completamente cargado

  const mapTamano = {
    height: '100vh',
    width: '100%',
  };

  const mapOptions = {
    streetViewControl: false,
    styles: [
      ...mapStyles,
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };

  const defaultCenter = { lat: -33.4489, lng: -70.6693 }; // Centro inicial del mapa

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAvZVnUS5Acu-vBJPXqak-ET6o7watzIbc"
      loadingElement={<div>Cargando el mapa...</div>}
    >
      <GoogleMap
        mapContainerStyle={mapTamano}
        zoom={11}
        center={defaultCenter}
        options={mapOptions}
        onLoad={(map) => {
          mapRef.current = map; // Almacena la referencia al mapa
          setMapLoaded(true); // Marca el mapa como cargado
        }}
      >
        <TrafficLayer autoUpdate />
      </GoogleMap>
    </LoadScript>
  );
};

export default MostrarMapa;
