import React from "react";
import { createBrowserRouter } from "react-router-dom";
import AppLayout from "../components/layout/AppLayout";
import LoginPage from "../pages/LoginPage";
import MainLayout from "../components/layout/MainLayout";
import DashboardPage from "../pages/DashboardPage";
import MapaPage from "../pages/MapaPage";
import UsuariosPage from "../pages/UsuariosPage";
import DestinosPage from "../pages/DestinosPage";
import ConfiguracionPage from "../pages/ConfiguracionPage";
import RutasPage from "../pages/RutasPage";
import ProximamentePage from "../pages/ProximamentePage";
import PrivateRoute from "./PrivateRoute";
import NoAutorizadoPage from "../pages/NoAutorizadoPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <LoginPage />
      },
      {
        path: "/",
        element: <MainLayout />,
        children: [
          {
            path: "inicio",
            element: <PrivateRoute component={MapaPage} rolesPermitidos={[1, 2]} />
          },
          {
            path: "mapa",
            element: <PrivateRoute component={DashboardPage} rolesPermitidos={[1, 2]} />
          },
          {
            path: "usuarios",
            element: <PrivateRoute component={UsuariosPage} rolesPermitidos={[1]} />
          },
          {
            path: "destinos",
            element: <PrivateRoute component={DestinosPage} rolesPermitidos={[1]} />
          },
          {
            path: "configuracion",
            element: <PrivateRoute component={ConfiguracionPage} rolesPermitidos={[1]} />
          },
          {
            path: "rutas",
            element: <PrivateRoute component={RutasPage} rolesPermitidos={[1, 2]} />
          },
          {
            path: "proximamente",
            element: <PrivateRoute component={ProximamentePage} rolesPermitidos={[1, 2]} />
          }
        ]
      }
    ]
  },
  {
    path: "/no-autorizado",
    element: <NoAutorizadoPage />
  }
]);
