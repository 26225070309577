import React, { useState } from "react";
import axios from 'axios';
import { Box, Button, CircularProgress, Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography, colors } from "@mui/material";
import { images } from "../assets";
import { Link, useNavigate } from "react-router-dom";
import Animate from "../components/common/Animate";

const LoginPage = () => {
  const navigate = useNavigate();
  const [onRequest, setOnRequest] = useState(false);
  const [rut, setRut] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validateInputs = () => {
    if (rut.trim() === '' || password.trim() === '') {
      setErrorMessage('Todos los campos son obligatorios.');
      return false;
    }
    return true;
  };

  const onSignin = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;

    setOnRequest(true);
    setErrorMessage('');

    try {
      const response = await axios.post('https://cronos.edgarleal.es/token/api/web/Login/ValidarUsuario.php', {
        rut,
        password
      });

      const data = response.data;

      if (response.status === 200 && data.success) {
        // Guardar el token y el tiempo de inicio de sesión en localStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('sessionStart', Date.now());
        // Guardar datos del usuario
        localStorage.setItem('userData', JSON.stringify(data.data));

        // Mostrar datos del usuario en la consola
        console.log("Datos del usuario:", data.data);

        navigate("/inicio");
      } else {
        setErrorMessage(data.message || 'Credenciales incorrectas');
      }
    } catch (error) {
      setErrorMessage('Error en el servidor. Inténtalo más tarde.');
    } finally {
      setOnRequest(false);
    }
  };

  return (
    <Box position="relative" height="100vh" sx={{ "::-webkit-scrollbar": { display: "none" } }}>
      <Box sx={{
        position: "absolute",
        right: 0,
        height: "100%",
        width: "80%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${images.loginBg})`
      }} />
      <Box sx={{
        position: "absolute",
        left: 0,
        height: "100%",
        width: { xl: "30%", lg: "40%", md: "50%", xs: "100%" },
        transition: "all 1s ease-in-out",
        bgcolor: colors.common.white
      }}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          "::-webkit-scrollbar": { display: "none" }
        }}>
          <Box sx={{ textAlign: "center", p: 5 }}>
            <Animate type="fade" delay={0.5}>
              <img src={images.logo} alt="logo" height={200} width={200} />
            </Animate>
          </Box>
          <Box sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            "::-webkit-scrollbar": { display: "none" }
          }}>
            <Animate type="fade" sx={{ maxWidth: 400, width: "100%" }}>
              <Box component="form" maxWidth={400} width="100%" onSubmit={onSignin}>
                <Stack spacing={3}>
                  <TextField
                    label="Rut"
                    fullWidth
                    value={rut}
                    onChange={(e) => setRut(e.target.value)}
                    error={!!errorMessage}
                  />
                  <TextField
                    label="Contraseña"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!errorMessage}
                  />
                  {errorMessage && (
                    <Typography color="error">{errorMessage}</Typography>
                  )}
                  <Button type="submit" size="large" variant="contained" color="primary" disabled={onRequest}>
                    Ingresar
                  </Button>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <FormGroup>
                      <FormControlLabel control={<Checkbox />} label="Recordar" />
                    </FormGroup>
                    <Typography color="error" fontWeight="bold">
                      <Link to="#">Olvidaste tu contraseña?</Link>
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Animate>
          </Box>
          <Box sx={{ textAlign: "center", p: 5, zIndex: 2 }}>
            <Animate type="fade" delay={1}>
              <Typography display="inline" fontWeight="bold" sx={{ "& > a": { color: colors.red[900], ml: "5px" } }}>
                No tienes cuenta? - <Link to="#">Contactanos</Link>
              </Typography>
            </Animate>
          </Box>
          {onRequest && (
            <Stack alignItems="center" justifyContent="center" sx={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              bgcolor: colors.common.white,
              zIndex: 1000
            }}>
              <Box position="relative">
                <CircularProgress variant="indeterminate" sx={{ color: colors.grey[200] }} size={100} />
              </Box>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
