import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, LoadScript, DirectionsRenderer, TrafficLayer } from '@react-google-maps/api';
import axios from 'axios';
import mapStyles from '../../assets/estilos/mapStyles'

const MapaRuta = ({ ruta, setDuraciones }) => {
    const [directionsRenderer, setDirectionsRenderer] = useState(null); // Estado para mantener la referencia al DirectionsRenderer

    const santiagoCoords = { lat: -33.4372, lng: -70.6506 };
    const googleMapsApiKey = 'AIzaSyB_1xTIBOHJ7f_mV7O1Tye6os6ie-s-fWE'; // Reemplaza con tu API Key de Google Maps

    useEffect(() => {
        const calcularRuta = async () => {
            if (ruta.length === 0) {
                limpiarRuta();
                return;
            }

            // Obtener la dirección de la empresa del localStorage
            const userData = JSON.parse(localStorage.getItem('userData'));
            const direccionEmpresa = userData ? userData.direccion_empresa : null;

            if (!direccionEmpresa) {
                console.error('No se encontró la dirección de la empresa');
                return;
            }

            // Construir la lista completa de puntos
            const puntosRutaCompleta = construirRutaCompleta(ruta, direccionEmpresa);
            const puntos = await obtenerCoordenadas(puntosRutaCompleta);

            if (puntos.length < 2) {
                limpiarRuta();
                return;
            }

            console.log('Puntos para la ruta:', puntos);

            // Limpiar la ruta antes de dibujar la nueva ruta
            limpiarRuta();

            // Crear un nuevo DirectionsRenderer
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: puntos[0].direccion,
                    destination: puntos[0].direccion,
                    waypoints: puntos.slice(1).map((punto, index) => ({
                        location: punto.direccion,
                        stopover: true,
                    })),
                    optimizeWaypoints: true,
                    travelMode: 'DRIVING',
                },
                (response, status) => {
                    if (status === 'OK') {
                        const newDirectionsRenderer = new window.google.maps.DirectionsRenderer({
                            directions: response,
                            options: { polylineOptions: { strokeColor: 'red' } }
                        });
                        setDirectionsRenderer(newDirectionsRenderer);

                        const duracionesCalculadas = calcularDuraciones(response.routes[0].legs);
                        setDuraciones(duracionesCalculadas);
                    } else {
                        console.error('Directions request failed due to ' + status);
                    }
                }
            );
        };

        calcularRuta();
    }, [ruta, setDuraciones]);

    const construirRutaCompleta = (ruta, direccionEmpresa) => {
        const puntosRutaCompleta = [
            {
                direccion: direccionEmpresa,
                latitud: santiagoCoords.lat,
                longitud: santiagoCoords.lng,
            },
            ...ruta
        ];

        return puntosRutaCompleta;
    };

    const obtenerCoordenadas = async (puntos) => {
        const puntosConCoordenadas = [];

        for (const punto of puntos) {
            try {
                const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(punto.direccion)}&key=${googleMapsApiKey}`);
                const { results } = response.data;
                if (results && results.length > 0) {
                    const { lat, lng } = results[0].geometry.location;
                    puntosConCoordenadas.push({
                        direccion: punto.direccion,
                        latitud: lat,
                        longitud: lng
                    });
                } else {
                    console.warn(`No results found for address: ${punto.direccion}`);
                }
            } catch (error) {
                console.error('Error obteniendo coordenadas:', error);
            }
        }

        return puntosConCoordenadas;
    };

    const calcularDuraciones = (legs) => {
        let horaEstimada = new Date();

        return legs.map((leg) => {
            const duracionSegundos = leg.duration.value;
            const duracionFormateada = formatDuration(duracionSegundos);

            const horaLlegada = new Date(horaEstimada.getTime() + duracionSegundos * 1000);
            horaEstimada = horaLlegada; // Actualizar hora estimada para el siguiente tramo

            return {
                desde: leg.start_address,
                hasta: leg.end_address,
                duration: duracionFormateada,
                horaEstimada: horaLlegada.toLocaleTimeString()
            };
        });
    };

    const formatDuration = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours} h ${minutes} min`;
    };

    const limpiarRuta = () => {
        if (directionsRenderer) {
            directionsRenderer.setMap(null); // Remover la referencia al mapa para limpiar la ruta
            setDirectionsRenderer(null); // Limpiar la referencia al DirectionsRenderer
        }
    };

    const mapOptions = {
        streetViewControl: false,
        trafficControl: true,
        styles: mapStyles,
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LoadScript googleMapsApiKey={googleMapsApiKey}>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '400px', marginBottom: '20px' }}
                    center={santiagoCoords}
                    zoom={10}
                    options={{ ...mapOptions, clickableIcons: false }} // Desactivar los iconos clicables de Google Maps
                >
                    {directionsRenderer && <DirectionsRenderer directions={directionsRenderer.getDirections()} options={{ polylineOptions: { strokeColor: 'red' } }} />}

                    {ruta.map((punto, index) => (
                        <Marker
                            key={index}
                            position={{ lat: parseFloat(punto.latitud), lng: parseFloat(punto.longitud) }}
                            label={String.fromCharCode(65 + index)} // Mostrar letras A, B, C, ...
                            clickable={false} // Desactivar la capacidad de clic en el marcador
                        />
                    ))}
                    <TrafficLayer autoUpdate />
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default MapaRuta;
